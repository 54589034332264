/*
 * @Author: wjc
 * @Date: 2023-10-31 14:58:28
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-08 15:26:48
 * @Description:
 */
export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export const defaultScreenConfig: {
  [key in ScreenSize]: number
} = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1920,
}
export const defaultScreenSpan = {
  xs: 20,
  sm: 20,
  md: 15,
  lg: 15,
  xl: 15,
}

export function useScreen() {
  const screenSize = reactive({
    width: 0,
    height: 0,
  })
  /**
   * 当前屏幕尺寸标识
   */
  const current = ref<ScreenSize>('lg')
  /**
   * 响应式布局栅格数量
   */
  const span = ref(defaultScreenSpan[current.value])
  /**
   * 获取窗口尺寸
   * @param w 宽度
   * @returns 当前窗口尺寸
   */
  const getSize = (w?: number) => {
    if (w === undefined) {
      w = screenSize.width
    }

    const { xs, sm, md, lg, xl } = defaultScreenConfig

    if (w <= xs) return 'xs'
    if (w <= sm) return 'sm'
    if (w <= md) return 'md'
    if (w <= lg) return 'lg'
    if (w <= xl) return 'xl'

    return 'xl'
  }

  const getSpan = () => {
    return span.value
  }

  /**
   * 监听窗口尺寸变动
   */
  const onWinResize = () => {
    const { innerWidth, innerHeight } = window

    screenSize.width = innerWidth
    screenSize.height = innerHeight
    current.value = getSize()
    span.value = defaultScreenSpan[current.value]
  }
  const higherThan = (size: ScreenSize, defScreenSize?: ScreenSize) => {
    const { xs, sm, md, lg, xl } = defaultScreenConfig
    // const width = defaultScreenConfig[defScreenSize || current.value]
    if (size === 'xl') return screenSize.width >= xl
    if (size === 'lg') return screenSize.width >= lg
    if (size === 'md') return screenSize.width <= md
    if (size === 'sm') return screenSize.width <= sm
    if (size === 'xs') return screenSize.width <= xs

    return false
  }

  onMounted(() => {
    if (typeof window === 'undefined') return

    screenSize.width = window.innerWidth
    screenSize.height = window.innerHeight

    current.value = getSize(window.innerWidth)
    span.value = defaultScreenSpan[current.value]
    window.addEventListener('resize', onWinResize)
  })

  onUnmounted(() => {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', onWinResize)
  })

  return {
    getSize,
    screenSize,
    current,
    getSpan,
    span,
    higherThan,
  }
}
